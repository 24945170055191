.orderManagementTableCell {
    border-bottom: 0px !important;
    font-family: Barmeno, sans-serif !important;
    height: 43px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#accordian_TableRider_summary .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-eqpfi5-MuiAccordionSummary-content {
    margin: 0px;
  }

