.datagrid__container {
    position: relative;
    width: 100%;
    font-family: "Barmeno", sans-serif;
    text-align: center;
    table-layout: auto;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
}

.datagrid__header {
    height: 56px;
    background-color: #F8FAFC;
}

.datagrid__header__row {
    height: 54px;
    border-bottom: 1px solid #909090;
}

.datagrid__header_title {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.datagrid__body {
    background-color: #fff;
    overflow-y: auto;
}

.datagrid__body__row {
    height: 54px;
    border-bottom: 1px solid #F1F1F1
}

.datagrid__empty_rows__wrapper {
    width: 100%;
    min-height: 256px;

    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

.datagrid__empty_rows__text {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}

.action_cell__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}
