.calendar__event {
  padding: 8;
  min-height: 24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008;
  overflow: clip;
}

.calendar__event[data-after="true"] {
  background-color: #FEF6E7;
  border-left: 4px solid #F59E0B;
}

.calendar__event[data-status="on_duty"][data-after="true"] {
  background-color: #FEF6E7;
  border-left: 4px solid #F59E0B;
}

.calendar__event[data-status="on_duty"] {
  background-color: #EAF1EB;
  border-left: 4px solid #2C6F37;
}

.calendar__event[data-status="on_leave"][data-after="true"] {
  background-color: #FCE8E9;
  border-left: 4px solid #E11B22;
}

.calendar__event[data-status="on_leave"][data-after="false"] {
  background-color: #FCE8E9;
  border-left: 4px solid #E11B22;
}

.calendar__event__status {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;
}

.calendar__event[data-view="day"],
.calendar__event[data-view="month"] {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.calendar__event[data-view="week"] {
  height: 100%;
}

.calendar__event[data-view="month"] {
  background-color: #FEF6E7 !important;
  border-left: 4px solid #F59E0B !important;
}

.calendar__event__default {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: clip;
}

.calendar__event__login {
  padding: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #FFFFFF;
  color: #2C6F37;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  border: 0.5px solid #2C6F37;
  border-radius: 8px;
  cursor: pointer;
}

.calendar__event__login:disabled {
  background-color: #EAEAEA;
  color: #1f1f1f;
  border-color: #EAEAEA;
  cursor: not-allowed;
}

.calendar__event__login > span {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.calendar__event__role_marker {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  aspect-ratio: 1;
}

.calendar__event__role_marker[data-role="Chef"] {
  background-color: #8C7608 !important;
}

.calendar__event__role_marker[data-role="In Store Manager"] {
  background-color: #34594C !important;
}

.calendar__event__role_marker[data-role="In Store Operator"] {
  background-color: #0F4ABF !important;
}

.calendar__event__role_marker[data-role="Rider/Driver"] {
  background-color: #D9A689 !important;
}

.calendar__event__role_marker[data-role="Online Order Manager"] {
  background-color: #D9B4C8 !important;
}

.calendar__event__title {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.calendar__event__timestamp {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
}

.event_info__wrapper {
  padding: 8px !important;
  width: 100% !important;
  max-width: 256px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008 !important;
}

.event_info__header {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #F1F1F1;
}

.event_info__header > span {
  font-size: 14px;
  font-weight: 500;
}

.event_info__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
}

.event_info__body__title {
  display: flex;
  flex-direction: column;
}

.event_info__title[data-status="on_duty"] {
  color: #F59E0B;
}

.event_info__title[data-status="on_leave"] {
  color: #E42328;
}

.event_info__login_logout {
  display: flex;
  flex-direction: column;
}

.event_info__total_hours {
  display: flex;
  flex-direction: column;
}

.event_info__total_hours > span:first-child {
  color: #2C6F37;
}

.event_info__title_header {
	padding: 6px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
