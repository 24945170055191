.flexRow24 {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.flexColumn24 {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.newOrderCreationContainer {
    background-color: #FAFAFACC;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    font-family: Barmeno, sans-serif;
}

.customerInformationContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    padding: 16px;
    gap: 24px;
    background-color: #FFF;
    box-sizing: border-box;
    width: 100%;
}

.previousOrderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    background-color: #FFF;
    width: 100%;
}

.CustomerInformationHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #1D1D1D;
}

.buttonMapIcon {
    box-shadow: 0px 2px 8px 0px #00000014;
    border-radius: 8px !important;
    width: 32px;
    height: 32px;
    padding: 6px 4px !important;
    box-sizing: border-box;
    gap: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.customerSearch {
    border: 1px solid #1D1D1D;
    border-radius: 8px !important;
    max-width: 448px !important;
    width: 100% !important;
    padding: 16px 8px !important;
    height: 56px;
}

.searchButtonCustomer {
    background-color: #FFF;
    border: 1px solid #E11B22;
    border-radius: 8px;
    padding: 10px !important;
    text-transform: none;
    color: #E11B22;
    height: 36px;
    width: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.searchCustomerInput {
    border-color: 'black'
}

.customerDetailsCard {
    display: flex;
    flex-direction: column;
    background-color: #F7F4EF;
    box-shadow: 0px 2px 8px 0px #00000014;
    padding: 8px;
    border-radius: 8px;
}

.personalInformationContainer {
    padding: 8px 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px
}

.personalDetailKey {
    max-width: 135px;
    width: 100%;
    color: #747474;
    font-size: 16px;
    font-weight: 700;
}

.personalDetailValue {
    width: fit-content;
    color: #1D1D1D;
    font-size: 16px;
    font-weight: 700;
}

.headingStyle {
    font-size: 24px;
    font-weight: 700;
}

.repeatOrderButton {
    height: 44px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #E11B22;
    border: 1px solid #E11B22;
    background-color: #FFFFFF;
    font-weight: 500 !important;
    justify-content: center;
}

.repeatOrderDisableButton {
    height: 44px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #909090;
    background-color: #F1F1F1;
    font-weight: 500 !important;
    font-size: 16px;
    justify-content: center;
    border: 0px;
}

.newOrderButton {
    height: 44px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    background-color: #E11B22;
    font-weight: 500 !important;
    justify-content: center;
    border: 0px;
}

.newOrderButtonDisabled {
    height: 44px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #909090;
    background-color: #F1F1F1;
    font-weight: 500 !important;
    justify-content: center;
    border: 0px;
}

.unSelectedOrderType {
    background-color: #D4D4D4;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.selectedOrderType {
    background: linear-gradient(114.11deg, #409046 25.39%, rgba(25, 152, 35, 0.4) 94.9%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.orderTypeContainer {
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 16px 32px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    box-shadow: 0px 2px 8px 0px #00000014;
    align-items: center;
    cursor: pointer;
}

.phoneLineContainer {
    border: 1px solid #BFBFBF;
    padding: 10px 8px;
    max-width: 200px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    color: #A2A2A2;
    font-size: 16px;
    align-items: center;
}

tr.tableBodyCustomer:hover {
    background-color: #FEE2E2;
}

.table_cell_posOrderHistory {
    font-family: Barmeno, sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding: 0px 16px !important
}

.table_cell_posOrderHistory#tableInputRadio{
    padding: 0px !important;
    width: 14px;
}

.tableHeaderCells.tableFont.tableHeader0 {
    width: 34px;
  }

tr.tableBodyCustomer:hover {
    background-color: #FFFFFF;
}

tr.tableBodySelected{
    background-color: #FEE2E2;
}

.tableHeaderCells {
    width: 165px;
    box-sizing: border-box;
    padding: 8px 16px 8px 16px !important;
    font-family: Barmeno, sans-serif !important;
    font-weight: 700 !important;
    color: rgb(116, 116, 116) !important;
    font-size: 14px !important;
    border-bottom: 1px solid rgb(191, 191, 191) !important;
    background-color: rgb(255, 255, 255);
}

.tableHeaderCells:last-child {
    width: 40px;
}

.approved_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #059669;
    background-color: #D1FAE5;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.pending_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #D97706;
    background-color: #FEF3C7;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.declined_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #E42328;
    background-color: #FEE2E2;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.ready_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #7C71FE;
    background-color: #E2E8FE;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.picked_up_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #E73777;
    background-color: #FFD6E5;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.delivered_tag {
    display: inline-block;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    color: #662EB2;
    background-color: #D4B6FF;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    box-sizing: border-box;
}

.tableFont{
    font-family: Barmeno,sans-serif;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    border-radius: 50%;
  }
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    border-radius: 50%;
  }
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
    border-radius: 50%;
  }

  .pos-suggestions-dropdown {
    display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 5px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  width: 100%;
  color: #231F20 !important;
  top: 46px !important;
  box-sizing: content-box !important;
  left: -1px !important;
  padding-right: 0.2px;
  padding-left: 0.2px;
  cursor:pointer;
  }

@media screen and (max-width:1300px) {
    #customerReatedTray{
        flex-direction: column;
    }
}