.title {
    display: flex;
    gap: 2.5px;
}

.order_details_view{
    overflow-y: auto;
  max-height: calc(100vh - 64px);
}

.order_details_view .title {
    padding: 0px 24px 12px 24px ;
}

.order_details_view .title .order_id {
    font-family: "Barmeno", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    color: #1D1D1D;
}

.order_details_view .date_download {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 4px solid #D4D4D4;
}

.order_details_view .date_download .date_time {
    font-family: "Barmeno", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    color: #747474;
}

.order_details_view .date_download .invoice {
    font-family: "Barmeno",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #E11B22;
}

.order_details_view .date_download .button_download {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.payment-info-container{
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border-bottom: 1px dotted #D4D4D4;
}
.order_payment_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 56px;
    width: 100%;
    align-items: center;
    font-family: Barmeno, sans-serif;
    justify-content:space-between;
    padding: 0px 12px;
    box-sizing: border-box;
}

.order_payment_container_key{
    display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}
.continue-dots{
    writing-mode: vertical-lr;
  color: #2C6F37;
  font-size: 18px;
  width: 24px;
  height: 26px;
  padding: 0px 6px;

}
.order_information_container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: Barmeno, sans-serif;
}
.order_information_title{
    height: 56px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0px 24px;
}
.order_items_information_quantity{
    padding: 16px 36px;
    color:#747474;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.order_summary_amount{
    background-color: #FCE8E9;
    padding: 0px 24px;
    gap: 4px;
    display: flex;
    flex-direction: column;
}
.order_summary_amount_key{
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
}

.cancelInfoContainer{
    display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #FCE8E9;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
}