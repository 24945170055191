.statisticReportContainer{
    display: flex;
    flex-direction: column;
    gap:24px;
    padding: 24px;
    background-color: #fff;
}

.custom-datepicker {
  font-size: 16px;
  font-weight: 600;
  color: #747474;
  border: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  color: #747474 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.statisticReportTabBar{
  background-color: #e5e5e5;
  border-radius: 8px;
  padding: 4px;
  min-height: 44px !important;
  max-height: 44px !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.Mui-selected.tabsOptionTablistReports {
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 12px;
    box-sizing: border-box;
    color: #E11B22 !important;
  }

  .statisticReportTabBar .MuiTabs-indicator {
    visibility: hidden;
  }

.tabsOptionTablistReports{
    height: 36px;
  color: #909090 !important;
  min-height: 36px !important;
  max-height: 36px !important;
  text-transform: capitalize !important;

}

.statisticsContainerOnly{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.orderStatisticsContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #F1F1F1;
  width:100%;
}

.textFieldDate .MuiInputBase-input.MuiInput-input {
  font-size: 16px;
  font-family: Barmeno, sans-serif !important;
  color: rgb(116, 116, 116) !important;
}
.custom-datepicker .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0px;
}