.select__menu {
  margin-top: 8px;
  border-radius: 8px !important;
  font-family: "Barmeno", sans-serif !important;
}

.select__list {
  padding: 0px !important;
}

.select {
  min-width: 256px;
  height: 44px;
  background-color: #fff !important;
  border-radius: 8px !important;
  font-family: inherit !important;
}

.select__input {
  padding: 0px 12px !important;

  display: flex !important;
  align-items: center;
  gap: 8px;

  font-family: "Barmeno", sans-serif !important;
}

.select__item {
	font-family: "Barmeno", sans-serif !important;
	padding: 8px !important;
	width: 100%;
	justify-content: flex-start !important;
}

.select__item.selected {
  background-color: #2C6F37 !important;
  color: #fff !important;
}
