.sides_description_header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item_information_subsubcategory {
    padding: 12px 16px 12px 16px;
    background-color: #F8FAFC;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #2C6F37;
    border-radius: 4px;
}

.item_information_containers{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0px 40px 40px 40px;
    font-family: 'Barmeno', sans-serif;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
}
.item_information_action_containers{
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: calc(100% - 90px);
    overflow: hidden;
}
.product_description_container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow: auto;
    width: fit-content;
    max-width: 400px;
}

.sides_description_container {
    flex-grow: 1.2;
    overflow: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
}

.product_info_desc_container{
    border-radius: 16px;
    box-shadow: 2px 2px 1px 7px #00000008;
    background: linear-gradient(157.14deg, #FFFFFF 14.51%, rgba(255, 255, 255, 0) 116.56%, rgba(241, 232, 232, 0.5) 116.56%);
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: fit-content;
}

.allergen_info_container{
    border-radius: 16px;
    box-shadow: 0px 8px 32px 0px #0000000F;
    box-shadow: 0px 4px 8px 0px #00000008;
    background: #FFFFFF;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 282px;
    width: 100%;
    max-width: 400px;
}

.zigzag-line{
    position: absolute;
    z-index: 99;
    width: 100%;
    bottom: 0;
    left: 0;
}
.product_title_desc{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.product_title_action{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 700;
    max-width: 352px;
    align-items: center;
}
.price_background {
    background-image: url('../assets/price_background.png');
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 24px;
    line-height: 29px;
    height: 60px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
}
.allergen_title{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #E11B22;
}
.accordian_details{
    border-bottom: 1px solid #f1f1f1;
    box-sizing: border-box;
}
div#accordian_spicelevel_summary {
    padding: 0px 16px 16px 16px;
}

.spiceLevel_label{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Barmeno', sans-serif;
    gap: 24px;
    width: 100%;
}
.form_control_label_spicelevel{
    height: 62px;
    border-bottom: 1px solid rgb(241, 241, 241);
    display: flex;
    flex-direction: row;
    gap: 0px;
}

.form_control_label_spicelevel .MuiFormControlLabel-label{
    width: 100%;
}
.button_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.product_minus_add_button{
    border-radius: 4px !important;
  background-color: #BED2C154 !important;
  height: 44px;
  width: 44px;
}
.product_minus_add_button1{
    border-radius: 4px !important;
  background-color: #BED2C154 !important;
  height: 34px;
  width: 34px;
}
.order_history_card{
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0px 0px 0px;
    box-shadow: 0px 4px 8px 0px #443e3e70;
    border-radius: 8px;
}
.order_details_summary{
    display: flex;
  flex-direction: column;
  gap: 2px;

}
.order_number_dates{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 24px;
  font-size: 24px;
}
.order_time_detail{
    font-size: 14px;
    color: #747474;
    font-weight: 500;
}
.order_status_container{
    display: flex;
  flex-direction: column;
  padding: 0px 0px 24px 0px;
}
.order_status_heading{
    display: flex;
    padding: 12px 24px;
    gap: 10px;
    color: #2C6F37;
    font-size: 18px;
    font-weight: 700;
    text-transform:uppercase;
}

.order_status_images{
display: flex;
padding: 16px 24px;
}

.points_earned_details {
    display: flex;
    flex-direction: row;
    padding: 8px 24px;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
}

.restaurant_action_button{
    background-color: #FCE8E9;
    padding: 16px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
}

.items_rest_details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.estimated_delivery_time{
    padding: 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.items_details{
padding: 12px 0px 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #1D1D1D;
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
}
.items_details_heading{
    display: flex;
  flex-direction: row;
  gap: 4px;
  color: #747474;
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
}
.buttonContainerOrderHistory{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.reorderButton{
    display: flex;
    width: 100%;
    height: 56px;
    color: #fff !important;
    background-color: #E11B22 !important;
    border-radius: 8px;
    padding: 10px 32px 10px 32px;
    box-sizing: border-box;
    font-weight: 700;
}

.reorderButtonDisabled{
    display: flex;
    width: 100%;
    height: 56px;
    color: #909090 !important;
    background-color: #BFBFBF !important;
    border-radius: 8px;
    padding: 10px 32px 10px 32px;
    box-sizing: border-box;
    font-weight: 700;
}
.viewDetailsButton{
    display: flex;
    border: 1px solid #E11B22 !important;
    width: 100%;
    height: 56px;
    color: #E11B22 !important;
    background-color: #fff !important;
    border-radius: 8px;
    padding: 10px 32px 10px 32px;
    box-sizing: border-box;
    font-weight: 700;
}
.search_order_history_container{
    min-width: 184px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #BFBFBF;
  padding: 10px 8px;
  font-family: Barmeno, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #A2A2A2;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  max-width: 240px;
  width: 100%;
  overflow: clip;
}
.search_order_history_input{
  height: 24px;
  border-radius: 8px;
  border: 0px solid #BFBFBF;
  padding: 10px 8px;
  font-family: Barmeno, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #A2A2A2;
  width: 100%;
}

.no_result_search_found {
    width: 100%;
    height: 600px;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:24px
}

.heading_no_result{
font-weight: 700;
  font-size: 32px;
  color: #1D1D1D
}

.content_no_result{
    font-weight: 500;
  font-size: 18px;
  color: #747474;
}

@media (min-width: 1060px){
    .order_history_card_container{
        display: grid;
        padding: 8px;
        font-family: Barmeno, sans-serif;
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px 16px;
    }
}
@media (max-width: 744px){
    .item_information_action_containers{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 1059px){
    .order_history_card_container{
        display: grid;
        padding: 8px;
        font-family: Barmeno, sans-serif;
        grid-template-columns: auto;
        grid-gap: 32px 16px;
    }
}
.MuiFormControlLabel-root.form_control_label_spicelevel{
    height: 100%;
}
.background_product_image{
    aspect-ratio: 1;
}

.exclude_allergens_textArea{
    height: 82px;
    width: 100%;
    border: 1px solid #F59E0B;
    border-radius: 8px;
    background-color: #FFF8DC;
    padding: 10px;
    font-family: Barmeno,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #1D1D1D;
}
.wordCount_excludeAllergen{
    font-family: Barmeno,sans-serif;
    color: #525252;
    font-size: 12px;
    font-weight: 400;
}
.wordCount_excludeAllergen_error{
    font-family: 'Barmeno', sans-serif;
    font-size: 12px;
    color: #E11B22;
    font-weight: 500;
}
.wordCount_excludeAllergen_container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.exclude_allergen_container{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.spiceLevel_label p{
    margin: 0px;
    font-size: 14px; 
    color: #747474; 
    font-weight: 700 
}