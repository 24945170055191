.dashboard__wrapper * {
    margin: 0;
    font-family: "Barmeno", sans-serif !important;
    box-sizing: border-box;

    --border-radius: 8px;
    --card-background: #FFFFFF;
    --card-border: 1px solid #F1F1F1;
    --card-shadow: 0px 2px 8px 0px #00000014;
}

.dashboard__wrapper {
    position: relative;

    height: 100vh;

    background-color: #F3F2F7;
}

.dashboard__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 200px;

    background-color: #FFFFFF;
    border-right: 1px solid #EB666B;
    border-radius: 0 24px 24px 0;
    box-shadow: 0px 4px 8px 0px #E32B2B14;
}

.dashboard__main {
    container: admin-dashboard / inline-size;

    padding: 32px 24px;
    margin-left: 200px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    height: 100vh;
    overflow-y: auto;
    overflow-x: clip;
}

.dashboard__scroll {
    padding: 0 16px 16px 0;
    overflow-y: auto;
    overflow-x: clip;
}

.realtime_notification {
	padding: 48px;
    border: 8px solid #E11B22;
	border-radius: 8px !important;
}

.realtime_notification.new_order {
    border-color: #059669;
}

.realtime_notification__icon {
    fill: #E11B22;
    margin: auto;
}

.realtime_notification__icon.new_order {
    fill: #059669;
}

.realtime_notification__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
    gap: 24px;
}

.realtime_notification__header {
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.realtime_notification__header > h2 {
	margin: 0;
}

.realtime_notification__header > p {
	margin: 0;
}

.realtime_notification__order_details__item {
	display: flex;
	align-items: center;
	gap: 8px;
}

.realtime_notification__order_details__label {
	margin: 0;
	color: #747474;
	font-weight: 600;
}

.realtime_notification__order_details__label::after {
	content: ":";
}

.realtime_notification__order_details__value {
	margin: 0;
}

.realtime_notification__order_details__value.order_amount::before {
	content: "\00A3";
}

.realtime_notification__order_details__value.order_type {
	text-transform: capitalize;
}

.realtime_notification__buttons_group {
    width: 100%;
	display: flex;
	gap: 24px;
}

.realtime_notification__order_details {
    width: 100%;
	display: grid;
	grid-template-columns: repeat(2, minmax(200px, auto));
	column-gap: 24px;
	row-gap: 8px;
}

.realtime_notification__order_details > .realtime_notification__order_details__item:nth-child(2n) {
	justify-self: end;
}

.realtime_notification__title {
	display: flex;
	align-items: center;
    justify-content: center;
	gap: 8px;
}

.realtime_notification__title > p {
	margin: 0;
	font-size: 18px;
	font-weight: 600;
}

.realtime_notification__title > p:last-child {
    color: #2C6F37;
};
