.time_input__wrapper {
	width: fit-content;
}

.time_input {
  padding: 0 24 0 8;
	width: 124px;
	height: 48px;
	position: relative;
  display: flex;
  align-items: center;
	border: 1px solid #545454;
	border-radius: 8px;
  cursor: pointer;
}

.time_input[data-disabled="true"] {
	cursor: not-allowed;
	color: #545454;
	background-color: #ececec;
	border-color: #ececec;
}

.time_input__icon {
	position: absolute;
	right: 4px;
	top: 50%;
	transform: translateY(-50%);
	color: #808080;
}

.time_input__popover {
	border-radius: 8px !important;
	margin-top: 8px;
}

/* Time Input Styles */
.react-timekeeper {
	background-color: #fff !important;
	font-family: "Barmeno", sans-serif !important;
}
