.cf_settings__search {
  padding: 0px 12px;
  height: 48px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #FFFFFF;
  font-family: "Barmeno", sans-serif;
  color: #DADADA;
  border-radius: 8px;
  border: 1px solid #BFBFBF;
  cursor: text;
}

.cf_settings__wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.cf_settings__tabs {
  padding: 4px;
  background-color: #F1F1F1;
  border-radius: 8px;
  height: 48px !important;
}

.cf_settings__tab__indicator {
  display: none;
}

.cf_settings__tab {
  height: 40px !important;
  min-height: 40px !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  color: #909090;
  border-radius: 8px !important;
}

.cf_settings__tab__selected {
  color: #CD191F !important;
  background-color: #FFFFFF !important;
}

.cf_settings__content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.cf_settings__content__header > h2 {
  font-size: 20px;
  font-weight: 700;
}

.cf_settings__sections__wrapper {
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(526px, 1fr));
  gap: 32px;
  border-radius: 8px;
  border: 1px solid #D4D4D4;
}

.cf_settings__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cf_settings__section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.cf_settings__section__header > h3 {
  font-size: 18px;
  font-weight: 700;
}

.cf_settings__section__header > button {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  color: #E11B22;
  cursor: pointer;
}

.cf_settings__section__header > button:disabled {
  color: #a7a7a7;
}

.cf_settings__section__header > button:disabled:hover {
  background-color: transparent;
}

.cf_settings__section__header > button:hover {
  background-color: #e11b2220;
}

.cf_settings__section__header__error {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #E11B22;
  background-color: #e11b2220;
  border-radius: 8px;
}

.cf_settings__time_settings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.cf_settings__formats {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.cf_settings__store_hours {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.cf_settings__form_control {
  max-width: min-content !important;
}

.cf_settings__day_hours {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}

.cf_settings__store_hours > label {
  min-width: 96px;
}

.cf_settings__button_group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.cf_settings__button_group > button {
  width: 100% !important;
  max-width: 196px !important;
}

.cf_settings__placeholder {
  color: #747474;
  font-weight: 700;
}

.cf_settings__add_holiday__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cf_settings__add_holiday__header {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #D4D4D4;
}

.cf_settings__add_holiday__header > h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.cf_settings__add_holiday__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cf_settings__add_holiday__footer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.cf_settings__confirm_delete__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}

.cf_settings__confirm_delete__wrapper > img {
  width: auto;
  height: 256px;
  aspect-ratio: 1;
  object-fit: contain;
}

.cf_settings__confirm_delete__wrapper > h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.cf_settings__confirm_delete__wrapper > p {
  margin: 0;
  color: #747474;
}

.cf_settings__confirm_delete__button_group {
  margin: 24px 0 16px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.cf_settings__add_holiday__form__textarea_helper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cf_settings__drawer__wrapper {
  padding: 24px;
  display: flex !important;
  align-items: flex-start;
  font-family: "Barmeno", sans-serif;
}

.cf_settings__drawer__trigger {
  visibility: visible;
}

.cf_settings__holidays_wrapper {
  display: none;
}

@container settings-tab-wrapper (width > 1256px) {
  .cf_settings__drawer__trigger {
    visibility: hidden;
  }

  .cf_settings__holidays_wrapper {
    display: block;
  }
}

.cf_settings__content {
  container: settings-tab-wrapper / inline-size;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cf_settings__holiday__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.cf_settings__switch__root {
  padding: 14px 12px;
  margin: 0 !important;
  width: 100%;
  max-width: 300px;
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center !important;
  gap: 16px;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
}

.cf_settings__switch__label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Barmeno", sans-serif !important;
  line-height: 2 !important;
  color: #1D1D1D !important;
}

.cf_settings__description__wrapper {
  padding: 0 16px !important;
  max-width: 200px !important;
  display: flex;
  flex-direction: column;
  font-family: "Barmeno", sans-serif !important;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008 !important;
  border-radius: 16px !important;
}

.cf_settings__description__header {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #F1F1F1;
}

.cf_settings__description__header > h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.cf_settings__description__wrapper > p {
  font-size: 12px;
  line-height: 1.5;
  color: #747474;
}

.cf_settings__field {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.cf_settings__field__label {
  min-width: 196px !important;
}

.cf_settings__field__input {
  max-width: 96px;
}

.cf_settings__fields__grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.cf_settings__print {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cf_settings__print > h3 {
  margin: 0;
  font-size: 18px;
}

span.cf_settings__print__caption {
  color: #747474;
}

.cf_settings__print__control {
  padding: 0 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.cf_settings__print__control.bordered {
  border-bottom: 1px solid #F1F1F1;
}

.cf_settings__select__icon {
  top: 50% !important;
  transform: translateY(-50%);
}

.cf_settings__read_only {
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #E11B22;
}
