.dashboard__wrapper > * {
  margin: 0;
  font-family: "Barmeno", sans-serif !important;
  box-sizing: border-box;

  --border-radius: 8px;
  --card-background: #FFFFFF;
  --card-border: 1px solid #F1F1F1;
  --card-shadow: 0px 2px 8px 0px #00000014;
}

.dashboard__wrapper {
  container: dashboard-wrapper / inline-size;
  position: relative;

  height: 100vh;
}

.dashboard__sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  width: 200px;

  background-color: #FFFFFF;
  border-right: 1px solid #EB666B;
  border-radius: 0 24px 24px 0;
  box-shadow: 0px 4px 8px 0px #E32B2B14;
}

.dashboard__sidebar[data-hide="true"] {
  display: none;
}

.dashboard__main[data-hideSidebar="true"] {
  margin-left: 0;
}

.dashboard__main[data-gutter="true"] {
  padding: 32px 24px;
}

.dashboard__main {
  container: admin-dashboard / inline-size;

  margin-left: 200px;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100vh;
  overflow-y: auto;
  overflow-x: clip;
}

@container dashboard-wrapper (width > 2560px) {
  .dashboard__main {
    margin: auto;
    max-width: 1920px;
  }
}

.dashboard__scroll {
  padding: 0 16px 16px 0;
  overflow-y: auto;
  overflow-x: clip;
}
