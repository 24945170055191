.pos_notifications__wrapper {
	padding: 16px 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	background-color: #ffffff;
	border-radius: 8px;
	overflow-y: auto;
}

.pos__notifications__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
}

.pos__notifications__header > h2 {
	margin: 0;
	font-size: 18px;
	font-weight: 700;
}

.pos_notifications__body {
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 48px;
}

.pos__notifications__empty {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pos__notifications__empty > p {
	margin: 0;
	font-size: 24px;
	font-weight: 700;
}

.pos__notifications__list_wrapper {
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.pos__notifications__timeline > p {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
}

.pos__notifications__list {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.pos__notifications__item__image {
	width: 48px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pos__notifications__item__image[data-unread='true'] {
  fill: #E11B22;
}

.pos__notifications__item {
	padding: 12px 4px;
	display: flex;
	align-items: flex-start;
	gap: 16px;
	border-bottom: 1px solid #E2E8F0;
	transition: all 0.2s ease;
	cursor: pointer;
}

.pos__notifications__item:hover {
  box-shadow: 0px 2px 8px 2px #00000014;
}

.pos__notifications__item__content {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.pos__notifications__item__content > p {
  margin: 0;
  flex: 1;
  line-height: 1.5;
}

.pos__notifications__item__content__time {
	color: #8B91A9;
	font-size: 14px;
	font-weight: 500;
}
