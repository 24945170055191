.receipt__wrapper {
	padding: 24px 0;
	min-height: calc(100vh - 548px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;
}

.receipt__content {
	padding: 24px;
	width: 100%;
	max-width: 320px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 2px 8px 0px #00000014;
}

.receipt__content > h2 {
	margin: 0;
}

.receipt__form {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.receipt__printers__wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.receipt__printers__wrapper > h3 {
	margin: 0;
	font-size: 18px;
	font-weight: 900;
}

.receipt__printers__wrapper > ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.printIconBackButton{
	display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 14px !important;
  color: rgb(225, 27, 34) !important;
  cursor: pointer !important;
  width: fit-content;
  border: 1px solid rgb(225, 27, 34) !important;
  border-radius: 8px !important;
  background: white !important;
  margin-left: 16px !important;
}