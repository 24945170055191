.chef__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
}

.chef__header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.chef__tab__wrapper {
  padding: 2px !important;
  max-width: fit-content;
  border: 1px solid #1D1D1D;
  border-radius: 50px;
}

.chef__tab__hidden_indicator {
  display: none;
}

.chef__tab {
  padding: 10px 12px !important;
  min-width: 148px !important;
  color: #909090 !important;
  text-transform: capitalize !important;
}

.chef__tab__selected {
  padding: 10px 12px !important;
  min-width: 148px !important;
  background-color: #1D1D1D !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.chef__header__right {
  place-self: end;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chef__filters {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.chef__filters__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 64px;
  border-bottom: 1px solid #EAEAEA;
}

.chef__filters__title {
  font-size: 24px;
  font-weight: 700;
  color: #1D1D1D;
}

.chef__filters__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chef__filters__form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.chef__filters__form__title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #1D1D1D !important;
  font-family: "Barmeno", sans-serif !important;
}

.chef__filters__form__options {
  display: flex !important;
  flex-flow: row wrap !important;
  align-items: center !important;
  gap: 1rem !important;
}

.chef__filters__form__options__label {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1D1D1D !important;
  font-family: "Barmeno", sans-serif !important;
}

.chef__select__menu {
  margin-top: 4px;
  border-radius: 8px !important;
}

.chef__select__list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.chef__select {
  max-height: 48px;
  border-radius: 8px !important;
}

.chef__select__item {
  padding: 0 16px !important;
  max-height: 36px;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.chef__select__item.selected {
  background-color: #D1FAE5B2 !important;
}

.chef__role_selection__label {
  font-size: 14px !important;
  font-family: "Barmeno", sans-serif;
}

.chef__filters__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.chef__main {
  width: 100%;
  min-height: fit-content;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.chef__no_orders__main {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.chef__no_orders__main__text {
  font-size: 40px;
  color: #1D1D1D;
}

.chef__orders {
	padding: 24px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	justify-content: stretch;
	gap: 24px;
}

.chef__order__card {
  padding-bottom: 16px;
  width: 100%;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px 0px #00000014;
  border-radius: 8px;
  overflow: clip;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.chef__order__card:first-child {
  margin: 0;
}

.chef__order__card__header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #F1F1F1;
}

.chef__order__card__chef_note {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 16px;
  border-bottom: 1px dashed #2C6F37;
}

.chef__order__card__chef_note__title {
  color: #2C6F37;
}

.chef__order__card__header__order_number {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
}

.chef__order__card__body {
  flex: 1;
  margin: 1rem 0;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
}

.chef__order__card__body__item {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  column-gap: 0.5rem;
}

.chef__order__card__body__item[data-selected="true"] {
  background-color: #D1FAE5B2;
}

.chef__order__card__body__item__quantity {
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  font-weight: 700;
  color: #1D1D1D;
}

.chef__order__card__body__item__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.chef__order__card__body__item__name {
  font-size: 20px;
  font-weight: 700;
}

.chef__order__card__body__item__details__attributes {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
  color: #2C6F37;
}

.chef__order__card__body__item__details__sub_category {
  font-size: 18px;
  font-weight: 500;
  color: #2C6F37;
}

.chef__order__card__body__item__allergen_excluded {
  padding: 8px;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  background-color: #FFE0E180;
  color: #E42328;
  border-radius: 8px;
}

.chef__order__card__body__item__checkbox {
  flex: 1;
}

.chef__order__card__footer {
  padding: 16px 16px 0 16px;
  display: flex;
  gap: 1rem;
  border-top: 1px dashed #388B2B;
}

.chef__pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
