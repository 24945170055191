.dialog_wh .MuiDialog-container .MuiPaper-root .MuiDialogContent-root:first-child {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.helpIconButton{
  display: flex;
  flex-direction: row;
  gap: 2px;
  font-size: 14px !important;
  color: #E7494E !important;
  text-transform: capitalize;
  padding: 8px !important;
  position: relative;
  aspect-ratio: 1;
}

.order_status_heading {
	display: flex;
	align-items: center;
	padding: 0;
	grid-gap: 10px;
	gap: 10px;
	color: #2C6F37;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
}

.dropDownCancel {
    position: absolute;
    top: 9px;
    width: 167px;
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 2px 4px 0px #00000026;
    right: 35px
  }

.dropDownCancelOption{padding: 12px 16px;
    background-color: white;
    border-top: 1px solid #F1F1F1;
    color: #1D1D1D;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  cursor: pointer;
  }

  .dropDownCancelOption:hover{
    background-color: whitesmoke;
}

.cancelPopupContainer {
    padding: 0px 40px 50px 40px;
    display: flex;
    box-sizing: border-box;
    gap: 24px;
    flex-direction: column;
  }

  .cancelPopupHeadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 450px;
    align-self: center;
  }

  .cancelPopupMainHeading {
    font-size: 24px;
    font-weight: 700;
    color: #1D1D1D;
    text-align: center;
  }
  .cancelPopupSubHEading {
    font-size: 16px;
    font-weight: 500;
    color: #747474;
    text-align: center;
  }

.cancelPopupBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cancelPopupOrderBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
.cncelOrderInfoContainer{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;
  color: #747474;
}
.orderDescContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center
  }
  .orderDescContainerValue {
    font-weight: 500;
    color: #1D1D1D;
  }
.reasonKey{
    color:#747474;
    font-weight: 700;

}

.reasonKeyContainer .select-selected-disabled {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #a2a2a2;
}

.reasonKeyContainer .select-selected-disabled:after {
    transform: rotate(315deg) !important;
    top: 47px !important;
}

.reasonKeyContainer .select-selected:after {
    position: absolute;
    top: 47px;
    right: 15px;
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 0px solid transparent;
    border-right:  1px solid black;
    border-top:  1px solid black;
    transform: rotate(135deg);
}
.newOrderButton1Disabled {
  height: 56px;
  width: 252px;
  padding: 10px 16px !important;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #909090;
  background-color: #F1F1F1;
  font-weight: 500 !important;
  justify-content: center;
  border: 0px;
}

.newOrderButton1 {
    height: 56px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    background-color: #E11B22;
    font-weight: 500 !important;
    justify-content: center;
    border: 0px;
}

.repeatOrderButton1 {
    height: 56px;
    width: 252px;
    padding: 10px 16px !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #E11B22;
    border: 1px solid #E11B22;
    background-color: #FFFFFF;
    font-weight: 500 !important;
    justify-content: center;
}

.reasonKeyContainer .select-selected {
    font-family: 'Barmeno', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    border: 2px solid #231F20;
}

.reasonKeyContainer .optionSelected {
    font-family: 'Barmeno', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: black;
    padding: 12px 16px;
}

.reasonKeyContainer .form-control, .reasonKeyContainer .form-control:focus {
    height: 56px !important;
    width: 350px !important;
    border-radius: 8px !important;
    outline: none;
    border: 1px solid #313131;
  }

  .buttonContainer{
    display: flex;
  gap: 16px;
  flex-direction: row;
  }
