.staff_info__dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.staff_info__tabs {
  padding: 4px;
  background-color: #F8FAFC;
  border-radius: 8px;
  min-height: 56px !important;
}

.staff_info__tab__indicator {
  display: none;
}

.staff_info__tab {
  min-height: 56px !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  color: #909090;
}

.staff_info__tab__selected {
  min-height: 56px !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px;
  color: #CD191F !important;
  background-color: #FFFFFF !important;
  border-radius: 8px !important;
}

.staff_info__dialog__content {
  container: dialog-content / inline-size;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.staff_info__form__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

@container dialog-content (width > 640px) {
  .staff_info__form__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.staff_info__span {
  grid-column: span 2;
}

.staff_info__checkbox__label {
  font-family: "Barmeno", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1D1D1D;
}

.staff_info__checkbox__checked {
  color: #2C6F37 !important;
  fill: #2C6F37 !important;
}

.staff_info__attach_visa {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.staff_info__submit_group {
  display: flex;
  justify-content: stretch;
  gap: 24px;
}

.staff_info__reset__button {
  padding: 16px !important;
  width: 100%;
  color: #E11B22 !important;
  border: 1px solid #E11B22 !important;
  border-radius: 8px !important;
}

.staff_info__submit__button {
  padding: 16px !important;
  width: 100%;
  background-color: #E11B22 !important;
  border-radius: 8px !important;
}

.staff_info__label {
  font-family: "Barmeno", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #475569 !important;
}

.staff_info__asterisk {
  color: #E11B22 !important;
}

.staff_info__helper_text {
  font-family: "Barmeno", sans-serif !important;
}

.staff_info__menu {
  margin-top: 4px;
  border-radius: 8px;
}

.staff_info__list {
  padding: 0 !important;
  margin: 4px 0;

  padding: 4px !important;
  margin: 4px 0;

  display: flex;
  flex-direction: column;

  border-radius: 8px !important;
}

.staff_info__list_item {
  padding: 4px !important;
  border-radius: 8px !important;
}

.staff_info__list_item_text {
  font-family: "Barmeno", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #1D1D1D !important;
}

.staff_info__backend_errors {
  padding: 16px;
  background-color: rgba(225, 27, 34, 0.05);
  border-radius: 8px;
  list-style-position: inside;
  list-style-type: none;
}

.staff_info__backend_error {
  display: flex;
  align-items: center;
  gap: 8px;
}

.staff_info__backend_error_icon {
  color: #E11B22;
}

.staff_info__backend_error_text {
  font-family: "Barmeno", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #E11B22;
  line-height: 1;
  vertical-align: middle;
}

.staff_info__warning {
  padding: 12px 16px;
  background-color: #FEF3C7;
  border-left: 4px solid #D97706;
  border-radius: 8px;
}

.staff_info__warning > p {
  margin: 0;
  color: #D97706;
  font-weight: 500;
}

.staff_info__address_list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.staff_info__address_list > h4 {
	margin: 0;
}

.staff_info__address_list__rows {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
	list-style-type: none;
}

.staff_info__address_list__rows > li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.staff_info__address_list__actions {
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 8px;
}
