
.side-navigation {
  color: rgba(35, 31, 32, 1) !important;
}

.hide-header-sidebar {
  display: none;
}

.fav-menuContainer {
  position: relative;
}

@media screen and (min-width:1280px) {
    .myProfileHeader {
        height: 100px;
        background-color: #2C6F37;
        padding: 0px 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-family: Verona, sans-serif;
        font-size: 36px;
        font-weight: 400;
    }

    .side-navigation{
      text-transform: none !important;
      font-weight: 700;
      font-size: 14px !important;
      padding: 0px !important;
    }

    .my-profile {
        background-size: cover;
        display: flex;
        flex-direction: row;
        gap: 40px;
        width: 100%;

    }

    .side-panel {
        background-color: white;
        width: 23.7%;
        max-width: 370px;
        padding: 40px 24px 40px 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 550px;
    }

    .settings-button {
        height: 32px;
        padding: 8px 12px 8px 12px !important;
        width: 82.5%;
        text-transform: none !important;
        background-color: #E11B22 !important;
        color: white !important;
        border-radius: 8px !important;
        justify-content: start !important;
        font-size: 16px !important;
    }

    #not-clicked {
        background-color: transparent !important;
        color: #313131 !important;
    }

    .settings-options {
        padding: 6px 0px 6px 12px;
        font-size: 14px !important;
    }

    .settings-options-list {
        padding: 6px 16px 6px 16px;
        border-left: 2px solid #BFBFBF;
        font-family: barmeno, sans-serif;
        font-weight: 400;
        cursor: pointer;
        max-width: fit-content;
        color: rgba(35, 31, 32, 1) !important;
    }

    .main-panel {
      background-color: white;
      width: 50%;
      border-radius: 8px;
      margin: 40px auto;
      padding: 24px 80px 24px 80px;
      height: fit-content;
    }

    .personal-details {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .detail-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 18px !important;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
    }

    .details-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .details-content-data {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }

    .details-content-key {
        color: #747474;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
        width: 150px !important;
        text-align: end;
    }

    .details-content-value {
        color: #231F20;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
        text-align: start;
    }
    .personal-details-edit {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .detail-edit-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px !important;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
    }
    .custom-select {
        position: relative;
        font-family: Barmeno, sans-serif;
        border: 1px solid black;
        border-radius: 8px;
      }
      .custom-select select {
        display: none; /*hide original SELECT element:*/
      }
      .select-selected {
        background-color: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        display: flex;
        height: 40px;
      }

      .select-selected-disabled {
        background-color: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        display: flex;
        height: 40px;
      }

      .select-selected:after {
        position: absolute;
        content: "";
        top: 22px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #313131 transparent transparent transparent;
      }

      /*point the arrow upwards when the select box is open (active):*/
      .select-selected-disabled:after {
        position: absolute;
        content: "";
        top: 22px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #313131 transparent transparent transparent;
        display: none;
      }

      /*style the items (options), including the selected item:*/
      .select-items div,.select-selected {
        color: #313131;
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;
      }
      .select-items div,.select-selected-disabled {
        color: #313131;
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;
      }

      /*style items (options):*/
      .select-items {
        position: absolute;
        background-color: white;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0px 2px 8px 0px #00000014;

      }

      .optionSelected {
        height: 22px;
        border-radius: 4px 4px 0px 0px;
      }

      /*hide the items when the select box is closed:*/
      .select-hide {
        display: none;
      }
      .optionSelected:hover{
        background-color:#9EBDA3;

      }

      .form-profile{
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 32px;
        padding-bottom: 62px;
      }

      .details_field__wrapper{
        display: flex;
        flex-direction: column;
      }

      .details_field__wrapper .form-control, .details_field__wrapper .form-control:focus {
        height: 56px !important;
        width: 350px !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
      }
      .contact_form-field_popup .flag-dropdown {
        border: 1px solid #313131 !important;
      }
      /* .react-tel-input{
        margin-top: 10px !important;
      } */
      .react-tel-input .selected-flag {
        outline: none;
        position: relative;
        width: 38px;
        height: 100%;
        padding: 0 0 0 8px !important;
        border-radius: 8px 0 0 8px;
      }
      .react-tel-input .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: white;
        border-radius: 8px 0 0 8px;
      }

      .errors-profile {
        position: absolute;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
        margin-top: 88px;
        max-width: 350px;
      }
      .submitButtonDet {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
      }
      .submitButtonDisabledDet {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
      }
      .cancelButton{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #E11B23 !important;
        border: 1px solid #E11B23 !important;
        background-color: #ffffff !important;
      }


}


@media screen and (min-width:300px) and (max-width: 1279px) {
    .myProfileHeader {
        height: 100px;
        background-color: #2C6F37;
        padding: 0px 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        font-family: Verona, sans-serif;
        font-size: 36px;
        font-weight: 400;
    }

    .side-navigation{
      text-transform: none !important;
      font-weight: 700 ;
      font-size: 14px !important;
      padding: 0px !important;
    }

    .my-profile {
        background-size: cover;
        display: flex;
        flex-direction: row;
        gap: 40px;
        width: 100%;

    }

    .side-panel {
        background-color: white;
        width: 23.7%;
        max-width: 370px;
        padding: 40px 24px 40px 40px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 550px;
    }

    .settings-button {
        height: 32px;
        padding: 8px 12px 8px 12px !important;
        width: 82.5%;
        text-transform: none !important;
        background-color: #E11B22 !important;
        color: white !important;
        border-radius: 8px !important;
        justify-content: start !important;
        font-size: 16px !important;
    }

    #not-clicked {
        background-color: transparent !important;
        color: #313131 !important;
    }

    .settings-options {
        padding: 6px 0px 6px 12px;
        font-size: 14px !important;
    }

    .settings-options-list {
        padding: 6px 16px 6px 16px;
        border-left: 2px solid #BFBFBF;
        font-family: barmeno, sans-serif;
        font-weight: 400;
        cursor: pointer;
        max-width: fit-content;
        color: rgba(35, 31, 32, 1) !important;
    }

    .main-panel {
        background-color: white;
        width: 100%;
        border-radius: 8px;
        margin: 40px 40px 0 0;
        padding: 24px 80px 24px 80px;
        height: fit-content;
    }

    .personal-details {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .detail-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 18px !important;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
    }

    .details-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .details-content-data {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }

    .details-content-key {
        color: #747474;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
        width: 150px !important;
        text-align: end;
    }

    .details-content-value {
        color: #231F20;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
        text-align: start;
    }
    .personal-details-edit {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .detail-edit-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px !important;
        font-family: Barmeno, sans-serif;
        font-weight: 700;
    }
    .custom-select {
        position: relative;
        font-family: Barmeno, sans-serif;
        border: 1px solid black;
        border-radius: 8px;
      }
      .custom-select select {
        display: none; /*hide original SELECT element:*/
      }
      .select-selected {
        background-color: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        display: flex;
        height: 40px;
      }

      .select-selected-disabled {
        background-color: #FFFFFF;
        border-radius: 8px;
        align-items: center;
        display: flex;
        height: 40px;
      }

      .select-selected:after {
        position: absolute;
        content: "";
        top: 22px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #313131 transparent transparent transparent;
      }

      /*point the arrow upwards when the select box is open (active):*/
      .select-selected-disabled:after {
        position: absolute;
        content: "";
        top: 22px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #313131 transparent transparent transparent;
        display: none;
      }

      /*style the items (options), including the selected item:*/
      .select-items div,.select-selected {
        color: #313131;
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;
      }
      .select-items div,.select-selected-disabled {
        color: #313131;
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;
      }

      /*style items (options):*/
      .select-items {
        position: absolute;
        background-color: white;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0px 2px 8px 0px #00000014;

      }

      .optionSelected {
        height: 22px;
        border-radius: 4px 4px 0px 0px;
      }

      /*hide the items when the select box is closed:*/
      .select-hide {
        display: none;
      }
      .optionSelected:hover{
        background-color:#9EBDA3;

      }

      .form-profile{
        display: grid;
        grid-template-columns: auto;
        grid-gap: 32px;
        padding-bottom: 62px;
        justify-content: center;
      }

      .details_field__wrapper{
        display: flex;
        flex-direction: column;
      }

      .details_field__wrapper .form-control, .details_field__wrapper  .form-control:focus {
        height: 56px !important;
        width: 350px !important;
        border-radius: 8px !important;
        outline: none;
        border: 1px solid #313131;
      }
      .contact_form-field_popup .flag-dropdown {
        border: 1px solid #313131 !important;
      }
      .react-tel-input{
        margin-top: 10px !important;
      }
      .react-tel-input .selected-flag {
        outline: none;
        position: relative;
        width: 38px;
        height: 100%;
        padding: 0 0 0 8px !important;
        border-radius: 8px 0 0 8px;
      }
      .react-tel-input .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: white;
        border-radius: 8px 0 0 8px;

      }

      .errors-profile {
        position: absolute;
        font-size: 12px;
        color: #E42328;
        font-weight: 500;
        letter-spacing: 1px;
        margin-top: 88px;
        max-width: 350px;
      }
      .submitButtonDet {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #FFFFFF !important;
        background-color: #E11B23 !important;
      }
      .submitButtonDisabledDet {
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #909090 !important;
        background-color: #BFBFBF !important;
      }
      .cancelButton{
        border-radius: 8px !important;
        padding: 16px 0px !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        width: 200px !important;
        height: 54px;
        color: #E11B23 !important;
        border: 1px solid #E11B23 !important;
        background-color: #ffffff !important;
      }
}

@media screen and (min-width:300px) and (max-width: 960px){
.main-panel {
    background-color: white;
    width: 100%;
    border-radius: 8px;
    margin: 40px 40px 100px 0;
    padding: 24px 80px 24px 80px;
    height: fit-content;
}
}
@media screen and (min-width:300px) and (max-width: 790px){
    .main-panel {
        background-color: white;
        width: 100%;
        border-radius: 8px;
        margin: 40px 40px 100px 0;
        padding: 24px 80px 24px 80px;
        height: fit-content;
    }
    }
    @media screen and (min-width:300px) and (max-width: 690px){
        .main-panel {
            background-color: white;
            width: 100%;
            border-radius: 8px;
            margin: 40px 40px 100px 0;
            padding: 24px 80px 24px 80px;
            height: fit-content;
        }
        }
.update{
  text-transform: none;
  font-size: 14px;
  font-family: Barmeno, sans-serif;
  text-decoration: underline !important;
  color: #E11B22 !important;
  cursor: pointer;
  padding: 0px !important;
}
.updateDiv{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
}
input#outlined-basic:focus-visible{
border: 2px solid #313131 !important
}
.contact_form-field:focus {
  border-color: green;
}

.select-items {
	border-radius: 8px !important;
	box-shadow: 0px 2px 8px 8px #00000014;
	margin-top: 8px;
}

.custom-select {
	font-size: 14px;
	font-weight: 500;
	color: #1b1b1b;
}
