.stylingCartItems{
    padding: 12px;
    gap: 8px;
    border-radius: 8px;
    opacity: 999px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px 0px #00000014;
    background-color: #fff;
    justify-content: space-between;
}

.itemDescContainerrecommend{
display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 700;
}

.recommendedItemAdd{
    width: 100%;
  height: 44px;
  padding: 10px 16px !important;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #E11B22;
  opacity: 1;
  display: flex;
  background-color: #FFF;
  color: #E11B22;
  align-items: center;
  justify-content: center;
}

.itemNameFavContainer{
display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
