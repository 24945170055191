.mid-content {
    padding-top: 108px;
}

.bg-img {
    background-image: url("./Wingo_grey_bg.webp") !important;
    background-repeat: initial !important;
    background-size: 50% !important;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background-color: #9EBDA3;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #409046;
    background: linear-gradient(114.11deg, #409046 25.39%, rgba(25, 152, 35, 0.4) 94.9%);
    border-radius: 8px;
}