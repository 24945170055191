.checkout__wrapper {
	padding: 32px 24px;
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(1, minmax(200px, 1fr));
	gap: 16px;
	overflow-y: auto;
}

@media screen and (width > 1296px) {
	.checkout__wrapper {
		grid-template-columns: repeat(2, minmax(200px, 1fr));
	}
}

.checkout__section {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #fff;
	border-radius: 8px;
}

.checkout__cards {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #fff;
	border: 1px solid #f1f1f1;
	border-radius: 8px;
}

.checkout__cards.checkout__items_list {
	flex: 1 1;
	max-height: 60vh;
	overflow-y: auto;
}

.checkout__card__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.checkout__card__header > h2 {
	font-size: 24px;
	font-weight: 700;
}

.checkout__card__header__button {
  height: 42px !important;
  max-width: fit-content;
}

.checkout__customer_info__details__item {
	display: flex;
	align-items: center;
	gap: 16px;
}

.checkout__customer_info__details__item.title {
	min-width: 156px;
	font-size: 16px;
	font-weight: 700;
	color: #747474;
}

.checkout__stack__radio_group {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.checkout__payment_method__radio {
  margin: 0 !important;
  padding: 8px 16px;
  width: 100%;
  gap: 8px;
  border: 1px solid;
  border-radius: 8px;
}

.checkout__payment_method__radio[data-selected="true"] {
  background-color: rgba(234, 241, 235, 1);
	border-color: #409046;
}

.checkout__pay_on_delivery__radio_group {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout__inline__radio_group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout__radio {
  margin: 0 !important;
  gap: 8px;
}

.checkout__separator {
  width: 1px;
  height: 50%;
  background-color: #404040;
}

.payment_methods__card_details {
  margin-bottom: 24px;
  width: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
}

.payment_methods__card_details__short_input {
	max-width: 96px;
}

.checkout__order_type {
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout__buttons {
	display: flex;
	gap: 16px;
}

.checkout__select_order_time__subtext {
  font-size: 14px;
  color: #747474;
}

.radio_group__child {
  padding-left: 18px;
}

.checkout__total {
	gap: 0 !important;
}

.checkout__total__button {
	width: fit-content;
	font-weight: 500 !important;
	color: #E11B22 !important;
}

.checkout__total__row {
	padding: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.checkout__total__row.additional_items {
	background-color: #EAEAEA;
	border-radius: 8px;
}

.checkout__total__row__title {
	color: #747474;
	font-weight: 700;
}

.checkout__total__row__value {
	font-weight: 700;
}

.checkout__total__row__input {
	max-width: 72px;
	min-width: min-content !important;
	height: 36px;
	border: 1px solid #BFBFBF;
	border-radius: 8px;
}

.checkout__total__row__input__adornment {
	margin-left: 12px;
	margin-right: 0;
	color: #747474;
}

.checkout__total__row__input__pad {
	padding: 0 !important;
}

.checkout__total__separator {
	margin: 16px 0;
	height: 1px;
	background-color: #d4d4d4;
	border: none;
	border-radius: 8px;
}

.checkout__total__row.total > .checkout__total__row__title {
	color: inherit;
}

.checkout__total__row.total > .checkout__total__row__value {
	font-size: 24px;
}

.checkout__total__row.discount > .checkout__total__row__value {
	color: #E11B22;
}

.checkout__total__row__value::before {
	content: "\00A3";
}

.checkout_columns__amount__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.checkout_columns__amount__price::before {
	content: "\00A3";
}

.checkout_columns__amount__price.discounted {
	color: #E11B22;
}

.checkout_columns__amount__price.slashed {
	font-size: 14px;
	text-decoration-line: line-through;
}

.checkout__customer_info__details__edit {
	display: flex;
	gap: 16px;
}

.checkout__customer_info__details {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.checkout__cards.checkout__select_order_time {
	flex: 1;
}

.checkout__datetime__input {
	width: fit-content;
}

.checkout__datetime__time_wrapper {
	padding: 12px 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	border: 1px solid #1b1b1b;
	border-radius: 8px;
	cursor: pointer;
}

.checkout__datetime__wrapper {
	display: flex;
	gap: 8px 24px;
	flex-wrap: wrap;
}

.discount__wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.checkout__card__note_error {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.edit_customer__wrapper {
	padding: 24px;
	border-radius: 8px !important;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.edit_customer__name_fields {
	display: flex;
	align-items: center;
	gap: 24px;
}

.edit_customer__buttons {
	margin-top: 24px;
	display: flex;
	align-items: center;
	gap: 16px;
}

.checkout__customer_info__details__item__address {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.confirm_delete_address__paper {
	padding: 24px;
	text-align: center;
	border-radius: 8px !important;
}

.confirm_delete_address__image {
	margin-inline: auto;
	max-height: 300px;
	height: 100%;
	width: fit-content;
	aspect-ratio: 1;
}

.confirm_delete_address__paper > h3 {
	margin: 0;
	font-size: 20px;
	font-weight: 700;
}

.confirm_delete_address__paper > p {
	font-size: 16px;
	font-weight: 500;
}

.confirm_delete_address__buttons {
	margin-block: 16px;
	display: flex;
	gap: 16px;
}

.checkout__customer_info__radio_group__container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 16px;
}

.checkout__customer_info__radio_group__label {
	padding-inline: 8px;
}

.checkout__customer_info__details__item.address_list {
	margin: 0 !important;
	flex-direction: row;
	align-items: center;
}

.create_address__paper {
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 8px !important;
}

.create_address__paper > h3 {
	font-size: 24px;
	margin: 0;
}

.create_address__address_type {
	gap: 16px;
}

.create_address__address_type__root {
	margin: 0 !important;
	gap: 8px;
}

.create_address__form__grid {
	display: grid;
	grid-template-columns: repeat(2, auto);
	row-gap: 8px;
	column-gap: 16px;
}

.create_address__form__grid__input.span {
	grid-column-start: 1;
	grid-column-end: 3;
}

.checkout__customer_info__radio_group__root {
	margin: 0 !important;
}

.checkout__customer_info__radio_group__action {
	display: flex;
	align-items: center;
}

.checkout__customer_info__details__item.address {
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 8px;
}

.checkout__customer_info__details__item__menu_list {
	padding-block: 4px !important;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0;
}

.checkout__customer_info__details__item__menu_paper {
	padding: 0 8px !important;
	margin-top: 4px;
	border-radius: 8px !important;
}

.checkout__customer_info__details__item__menu_item {
	font-family: "Barmeno", sans-serif !important;
	width: 100% !important;
	align-items: flex-start !important;
	justify-content: flex-start !important;
	padding: 4px 8px !important;
}

.checkout__customer_info__details__item__menu_item.selected {
	background-color: transparent !important;
	color: #E11B22 !important;
}

.checkout__select_order_time__error {
	background-color: #FCE8E9;
	border-left: 4px solid #E11B22;
	color: #F87171;
	padding: 8px 16px;
	border-radius: 8px;
	font-weight: 500;
}

.checkout__order_type__list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.checkout__order_type__list.addresses {
	flex: 1;
	max-height: 196px;
}

.checkout__stack__radio_group.addresses {
	height: 100%;
	overflow-y: auto;
	flex-wrap: nowrap;
}

.checkout__order_type__list > h3 {
	font-size: 16px;
	font-weight: 500;
}

.checkout__stack__radio_group__addresses__item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.checkout__customer_info__details__address_missing {
	padding: 8px 16px;
	background-color: #FCE8E9;
	color: #E11B22;
	border-left: 4px solid #E11B22;
	border-radius: 8px;
	width: 100%;
	font-weight: 500;
}

.order_placed__wrapper {
	padding: 48px 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: center;
	border-radius: 8px !important;
}

.order_placed__wrapper > img {
	max-height: 312px;
	width: fit-content;
	margin-inline: auto;
	aspect-ratio: 1;
}

.order_placed__wrapper > h3 {
	margin: 0;
	font-size: 24px;
	font-weight: 700;
}

.order_placed__wrapper > p {
	margin: 0;
	font-weight: 500;
}
