.dashboard__header {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    column-gap: 16px;
}

.header__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.header__item.center {
    place-self: center;
	justify-self: center;
}

.header__item.end {
    place-self: center;
    justify-self: end;
}

.header__title {
    font-size: 24px;
    font-weight: 700;
    color: #1d1d1d;
}

.header__title[data-fullWidth='true'] {
	width: 100%;
}

.header__select {
    min-width: 200px;
    border-radius: 8px !important;
    font-family: inherit !important;
}

.header__select__input {
    padding: 12px !important;
    padding-right: 32px !important;

    display: flex !important;
    align-items: center;
    gap: 8px;

		background-color: #fff !important;
    border-color: #2C6F37 !important;
}

.header__select__menu {
    margin-top: 8px;
    border-radius: 8px !important;
    font-family: "Barmeno", sans-serif !important;
}

.header__select__list {
    padding: 0px !important;
}

.header__select__item {
    padding: 0px 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    grid-gap: 8px;
    gap: 8px;
    font-family: "Barmeno", sans-serif !important;
}

.header__role_selection__icon {
    width: 16px !important;
    height: 16px !important;
    color: #E11B22;
    fill: #E11B22;
}

.header__select__item:hover {
    background-color: rgba(44, 111, 55, 0.5) !important;
}

.header__select__item.selected {
    background-color: #2C6F37 !important;
    color: #fff !important;
    fill: #fff !important;
}

.header__notifications {
	color: #18181b !important;
	background-color: #fff !important;
	border: 1px solid #bfbfbf !important;
	border-radius: 8px !important;
	aspect-ratio: 1;
	width: 48px;
}

.header__notifications__badge {
	background-color: #CD191F !important;
	color: #fff !important;
	font-weight: 700 !important;
	font-family: "Barmeno", sans-serif !important;
	line-height: 1.5 !important;
	font-size: 0.75rem !important;
}

.header__notifications__popover {
	margin-top: 16px;
	padding: 16px;
	max-width: 720px !important;
	width: 100%;
	max-height: 50vh !important;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	border-radius: 8px !important;
	overflow: visible !important;
	box-shadow: 0px 8px 8px -4px #0000000F, 8px 0px 8px -4px #0000000F !important;
}

.header__notifications__list_wrapper {
	flex: 1;
	padding-right: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow-y: auto;
}

.header__notifications__list {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.header__notifications__timeline > p {
    margin: 0;
	font-size: 16px;
	font-weight: 500;
}

.header__notifications__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.header__notifications__header > h2 {
	margin: 0;
	font-size: 18px;
}

.header__notifications__empty {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__notifications__empty > p {
	margin: 0;
	text-align: center;
	font-size: 18px;
	font-weight: 700;
}

.header__notifications__item {
	padding: 12px 4px;
	display: flex;
	align-items: flex-start;
	gap: 16px;
	border-bottom: 1px solid #E2E8F0;
	transition: all 0.2s ease;
	cursor: pointer;
}

.header__notifications__item__image {
	width: 48px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__notifications__item__image[data-unread='true'] {
  fill: #E11B22;
}

.header__notifications__item:hover {
  box-shadow: 0px 2px 8px 2px #00000014;
}

.header__notifications__item__content {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.header__notifications__item__content > p {
    margin: 0;
    flex: 1;
    line-height: 1.5;
}

.header__notifications__item__content__time {
	color: #8B91A9;
	font-size: 14px;
	font-weight: 500;
}

.header__notifications__footer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header__notifications__popover::before {
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 12px 8px;
	border-color: transparent transparent #fff transparent;
	background-color: transparent;
	top: -10;
	right: 16;
	content: "";
}
