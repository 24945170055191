.pos_profile__wrapper {
	padding: 16px 24px;
	height: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
	background-color: #FFFFFF;
	border-radius: 16px;
}

@media screen and (width < 1296px) {
  .pos_profile__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.pos_profile__section.main_content {
	grid-column-start: 1;
	grid-column-end: 4;
}

.pos_profile__section.sidebar {
	padding: 0 16px;
	grid-column-start: 4;
	grid-column-end: 6;
	border-radius: 8px;
	box-shadow: 0px 2px 8px 0px #00000014;
}

.pos_profile__block {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 16px;
}

.pos_profile__block__header {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	border-bottom: 1px solid #D4D4D4;
}

.pos_profile__title {
	display: flex;
	align-items: center;
	gap: 16px;
}

.pos_profile__title > h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 800;
}

.pos_profile__block__content {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.pos_profile__block__content > h3 {
	margin: 8px 0;
	font-size: 18px;
	font-weight: 800;
}

.pos_profile__expiry_popup__popover {
  border-radius: 8px !important;
  box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008 !important;
}

.pos_profile__expiry_popup {
  padding: 16px;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: "Barmeno", sans-serif;
}

.pos_profile__expiry_popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #F1F1F1;
}

.pos_profile__expiry_popup__title {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
}

.pos_profile__expiry_popup__message {
  padding: 8px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #747474;
}

.pos_profile__info__general_info__title {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
}

.pos_profile__info__general_info__row {
  display: flex;
  gap: 16px;
}

.hidden {
  display: none;
}

.pos_profile__info__general_info__row__label {
  max-width: 156px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #747474;
}

.pos_profile__info__general_info__row__value {
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-size: 18px;
  font-weight: 400;
  color: #1D1D1D;
}

.pos_profile__info__general_info__row__value__expiry {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1D1D1D;
  font-size: 16px;
}

.pos_profile__log__stack {
  max-height: 528px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: clip;
}

.pos_profile__log {
  width: 100%;
  position: relative;
  padding: 0 24px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.pos_profile__log__time {
  min-width: 72px;
  color: #747474;
  font-size: 12px;
  font-weight: 500;
}

.pos_profile__log__message_wrapper {
  flex: 1;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  background: #F8FAFC;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  overflow-x: clip;
}

.pos_profile__log__message {
  font-size: 12px;
  font-weight: 500;
  color: #1D1D1D;
}

.pos_profile__log__change {
  font-size: 12px;
  font-weight: 500;
  color: #747474;
}

.pos_profile__log__decorator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  overflow: clip;
}

.pos_profile__log__decorator__circle {
  width: 16px;
  height: 16px;
  border-radius: 50%; /* This creates the outer circle */
  background: linear-gradient(135deg, #409046 0%, rgba(25, 152, 35, 0.4) 100%);
  position: relative;
}

.pos_profile__log__decorator__circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;  /* Circle with radius 3 */
  height: 6px;
  border-radius: 50%;
  background-color: #F8FAFC; /* Inner white circle */
}

.pos_profile__log__decorator__line {
  flex: 1;
  width: 2px;
  height: 100%;
  background: #D4D4D4;
  border-radius: 8px;
}
