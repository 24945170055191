.unauthorised__wrapper {
  padding: 24px;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  background-color: #fff;
  border-radius: 8px;
}

.unauthorised__image {
  height: auto;
  width: 100%;
  max-width: 520px;
}

.unauthorised__text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #1D1D1D;
}
