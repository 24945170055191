.orderStatisticsTopContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    max-width: 365px;
    width: 100%;
  }

  .productTopHeading{
    font-size: 20px;
  font-weight: 700;
  color: #1D1D1D;
  }
  .topPerformerDetailContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .topPerformingDetailProduct{
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    padding: 12px 0px;
    align-items: center;
  }
  .itemCountContainer{
    border-radius: 40px;
  background-color: #FEE2E2;
  padding: 2px 8px;
  color: #E42328;
  font-size: 12px;
  font-weight: 500;
  }