.gm-style-iw-chr{
    display: none !important;
}
.filterContainerMap{
    position: absolute;
    top:24px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 2px 8px 0px #00000014;
    background-color: #ffffff;
    border-radius: 8px;
  left: 24px;
  max-width: 200px;
  gap:16px
}